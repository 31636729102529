<h3>Edit Carrier Details</h3>

<div class="panel" *ngIf="carriers">
    <div class="panel-contain" style="min-height: 400px;">
        <div class="half-width first-half-option">
            <mat-form-field class="mat-form-field-no-padding" style="width: 400px;">
                <mat-select class="" name="carriers" [(ngModel)]="activeCarrier" (selectionChange)="carrierChanged()">
                    <mat-option *ngFor="let carrier of carriers" class="" [value]="carrier">{{carrier.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="half-width second-half-option top" *ngIf="activeCarrier">
            <input *ngIf="activeBUIsAssoctiatedWithActiveCarrier" type="button" class="button warning-btn float-right" style="margin-top: 20px; font-size: 1em;" (click)="disassociateCarrier()" value="Remove Association of {{activeBU.name}} with this Carrier" />
            <input *ngIf="!activeBUIsAssoctiatedWithActiveCarrier" type="button" class="button elevated-btn float-right" style="margin-top: 20px; font-size: 1em;" (click)="associateCarrier()" value="Associate {{activeBU.name}} with this Carrier" />
        </div>

        <div *ngIf="activeCarrier && activeBUIsAssoctiatedWithActiveCarrier">
            <form #carrierAddress="ngForm" id="carrier-address" *ngIf="activeCarrier.address" class="half-width save-group">
                <h4 class="inline">
                    Carrier Information
                    <span *ngIf="activeCarrier.address.editor" style="margin-left: 10px;" title="Last Updated on {{activeCarrier.address.editTime | date:'mediumDate'}} by {{activeCarrier.address.editor}}"><svg class="info-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z" /><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg></span>
                </h4>
                <button type="button" class="button float-right" style="margin-top: 20px;" (click)="updateAddress()" [ngClass]="{ 'disabled': carrierAddress.form.pristine }" [disabled]="carrierAddress.form.pristine">Save Address</button>
                <mat-form-field class="full-width mat-form-field-no-padding">
                    <input name="line1" matInput type="text" [(ngModel)]="activeCarrier.address.line1" class="proper-name" placeholder="Street Address" maxlength="30" />
                    <mat-hint *ngIf="activeCarrier.address.line1 && activeCarrier.address.line1.length === 30" align="end">Only 30 characters allowed per line</mat-hint>
                </mat-form-field>
                <mat-form-field class="full-width mat-form-field-no-padding">
                    <input name="line2" matInput type="text" [(ngModel)]="activeCarrier.address.line2" class="proper-name" placeholder="Address Line 2" maxlength="30" />
                </mat-form-field>

                <mat-form-field class="half-width first-half-option mat-form-field-no-padding">
                    <input name="city" matInput type="text" [(ngModel)]="activeCarrier.address.city" class="proper-name" placeholder="City" />
                </mat-form-field>

                <div class="half-width second-half-option">
                    <pv-state-select [(state)]="activeCarrier.address.state" class="half-width first-half-option inline mat-form-field-no-padding"></pv-state-select>

                    <mat-form-field class="half-width second-half-option mat-form-field-no-padding">
                        <input name="zip" matInput type="text" [(ngModel)]="activeCarrier.address.zip" placeholder="Zip" />
                    </mat-form-field>
                </div>
                <mat-form-field class="half-width mat-form-field-no-padding">
                    <input name="phone" matInput type="text" phoneMask (ngModelChange)="activeCarrier.address.phone = $event" [ngModel]="activeCarrier.address.phone | phoneMaskPipe" placeholder="Phone" />
                </mat-form-field>
            </form>


            <h4>State Specific Configuration</h4>

            <pv-state-select [(state)]="selectedState" class="half-width first-half-option inline top" (stateChange)="stateChanged()"></pv-state-select>
            <span class="state-message">{{stateMessage}}</span>

            <div *ngIf="selectedState && carrierStateDetails && authorizedSigners">

                <div *ngIf="authorizedSigners" class="save-group inline" style="margin-bottom: 20px;">
                    <h5>
                        Authorized Signer
                        <span *ngIf="activeAuthorizedSigner && activeAuthorizedSigner.editor" style="margin-left: 10px;" title="Last Updated on {{activeAuthorizedSigner.editTime | date:'mediumDate'}} by {{activeAuthorizedSigner.editor}}"><svg class="info-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z" /><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg></span>
                    </h5>
                    <mat-form-field class="mat-form-field-no-padding" style="width: 400px;" id="auth-signer-selection">
                        <mat-select class="" name="authorizedSigners" [(ngModel)]="activeAuthorizedSigner" (selectionChange)="authSignerChanged()">
                            <mat-option *ngFor="let signer of authorizedSigners" class="" [value]="signer">{{signer.fullName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="!editingAuthorizedSigner" class="inline">
                        <input type="button" [ngClass]="{ 'disabled': !activeAuthorizedSigner }" [disabled]="!activeAuthorizedSigner" class="button" value="Edit Signer's Details" style="margin: 0 25px;" (click)="editingAuthorizedSigner = true;" />
                        <input type="button" class="button" value="Add New Signer" (click)="addNewAuthorizedSigner()" />
                    </div>
                    <form #authSigner="ngForm" *ngIf="activeAuthorizedSigner && editingAuthorizedSigner" id="state-auth-signer">
                        <div>
                            <button type="button" class="button float-right" style="margin-top: 20px;" (click)="updateAuthorizedSigner()" [ngClass]="{ 'disabled': authSigner.form.pristine }" [disabled]="authSigner.form.pristine">Save Authorized Signer</button>
                            <mat-form-field class="third-width mat-form-field-no-padding">
                                <input name="authSignerFirstName" matInput type="text" [(ngModel)]="activeAuthorizedSigner.firstName" class="proper-name" placeholder="First Name" />
                            </mat-form-field>
                            <mat-form-field class="third-width middle-option mat-form-field-no-padding">
                                <input name="authSignerLastName" matInput type="text" [(ngModel)]="activeAuthorizedSigner.lastName" class="proper-name" placeholder="Last Name" />
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field class="third-width mat-form-field-no-padding">
                                <input name="authSignerTitle" matInput type="text" [(ngModel)]="activeAuthorizedSigner.title" class="proper-name" placeholder="Title" />
                            </mat-form-field>
                            <mat-form-field class="third-width middle-option mat-form-field-no-padding">
                                <input name="authSignerEmail" matInput type="text" [(ngModel)]="activeAuthorizedSigner.email" placeholder="eMail Address" />
                            </mat-form-field>
                            <mat-form-field class="third-width mat-form-field-no-padding">
                                <input name="authSignerPhone" matInput type="text" phoneMask (ngModelChange)="activeAuthorizedSigner.address.phone = $event" [ngModel]="activeAuthorizedSigner.address.phone | phoneMaskPipe" placeholder="Phone" />
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field class="half-width first-half-option mat-form-field-no-padding">
                                <input name="authSignerLine1" matInput type="text" [(ngModel)]="activeAuthorizedSigner.address.line1" class="proper-name" placeholder="Street Address" maxlength="30" />
                                <mat-hint *ngIf="activeAuthorizedSigner.address.line1.length === 30" align="end">Only 30 characters allowed per line</mat-hint>
                            </mat-form-field>
                            <mat-form-field class="half-width second-half-option mat-form-field-no-padding">
                                <input name="authSignerLine2" matInput type="text" [(ngModel)]="activeAuthorizedSigner.address.line2" class="proper-name" placeholder="Address Line 2" maxlength="30" />
                            </mat-form-field>

                            <mat-form-field class="half-width first-half-option mat-form-field-no-padding">
                                <input name="authSignerCity" matInput type="text" [(ngModel)]="activeAuthorizedSigner.address.city" class="proper-name" placeholder="City" />
                            </mat-form-field>

                            <div class="half-width second-half-option">
                                <pv-state-select [(state)]="activeAuthorizedSigner.address.state" class="half-width first-half-option inline mat-form-field-no-padding"></pv-state-select>

                                <mat-form-field class="half-width second-half-option mat-form-field-no-padding">
                                    <input name="authSignerZip" matInput type="text" [(ngModel)]="activeAuthorizedSigner.address.zip" placeholder="Zip" />
                                </mat-form-field>
                            </div>
                        </div>
                    </form>
                </div>

                <form #carrierStateContact="ngForm" class="save-group" id="carrier-state-contact">
                    <h5 class="inline">
                        Contact
                        <span *ngIf="carrierStateDetails.editor" style="margin-left: 10px;" title="Last Updated on {{carrierStateDetails.editTime | date:'mediumDate'}} by {{carrierStateDetails.editor}}"><svg class="info-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z" /><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg></span>
                    </h5>
                    <button type="button" class="button float-right" style="margin-top: 20px;" (click)="updateContact()" [ngClass]="{ 'disabled': carrierStateContact.form.pristine }" [disabled]="carrierStateContact.form.pristine">Save Contact</button>
                    <div class="clear-float">
                        <mat-form-field class="third-width mat-form-field-no-padding">
                            <input name="licenseNumber" matInput type="text" [(ngModel)]="carrierStateDetails.licenseNumber" placeholder="License Number" />
                        </mat-form-field>
                        <mat-form-field class="third-width middle-option mat-form-field-no-padding">
                            <input name="licenseState" matInput disabled type="text" [(ngModel)]="carrierStateDetails.licenseState" placeholder="License State" />
                        </mat-form-field>
                        <mat-form-field class="third-width mat-form-field-no-padding">
                            <input name="contactCocode" matInput type="text" [(ngModel)]="carrierStateDetails.companyCode" placeholder="CoCode" />
                        </mat-form-field>
                        <div>
                            <mat-form-field class="third-width mat-form-field-no-padding">
                                <input name="contactName" matInput type="text" [(ngModel)]="carrierStateDetails.contactName" placeholder="Contact Name" />
                            </mat-form-field>
                            <mat-form-field class="third-width middle-option mat-form-field-no-padding">
                                <input name="contactTitle" matInput type="text" [(ngModel)]="carrierStateDetails.contactTitle" placeholder="Contact Title" />
                            </mat-form-field>
                            <mat-form-field class="third-width mat-form-field-no-padding">
                                <input name="contactPhone" matInput type="text" phoneMask (ngModelChange)="carrierStateDetails.contactPhone = $event" [ngModel]="carrierStateDetails.contactPhone | phoneMaskPipe" placeholder="Contact Phone" />
                            </mat-form-field>
                        </div>
                    </div>
                </form>

                <div class="smallerhalf-width" style="margin-right: 2%;">
                    <div>
                        <h5 class="inline">
                            License Types Allowed for Appointment
                            <span *ngIf="allowedLicenseTypeEditor" style="margin-left: 10px;" title="Last Updated on {{allowedLicenseTypeEditTime | date:'mediumDate'}} by {{allowedLicenseTypeEditor}}"><svg class="info-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z" /><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg></span>
                        </h5>
                        <input type="button" class="button float-right" value="Add" style="margin: 20px 0 0 0;" (click)="addLtpAllowed()" />
                    </div>
                    <mat-table [dataSource]="filteredLTPs" *ngIf="activeCarrier.allowedLicenseTypes && activeCarrier.allowedLicenseTypes.length > 0">

                        <ng-container matColumnDef="type">
                            <mat-header-cell *matHeaderCellDef>License Type</mat-header-cell>
                            <mat-cell *matCellDef="let ltpMapping" class="proper-name">
                                <ng-container *ngIf="ltpMapping.isEditing">
                                    <select name="ltpMappingSelect" [compareWith]="compareLtpLoaFn" [(ngModel)]="ltpMapping.regedLicenseType">
                                        <option *ngFor="let ltp of stateRegedLTPs" [ngValue]="ltp">{{ltp.licenseType}}</option>
                                    </select>
                                </ng-container>
                                <ng-container *ngIf="!ltpMapping.isEditing">
                                    {{ltpMapping.regedLicenseType.licenseType}}
                                </ng-container>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="producerType">
                            <mat-header-cell *matHeaderCellDef style="flex: .75;">Producer Type</mat-header-cell>
                            <mat-cell *matCellDef="let ltpMapping" style="flex: .75;">
                                <ng-container *ngIf="ltpMapping.isEditing">
                                    <select [(ngModel)]="ltpMapping.producerType">
                                        <option value="Agent">Agent</option>
                                        <option value="Agency">Agency</option>
                                        <option value="All">All</option>
                                    </select>
                                </ng-container>
                                <ng-container *ngIf="!ltpMapping.isEditing">
                                    {{ltpMapping.producerType}}
                                </ng-container>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <mat-header-cell *matHeaderCellDef style="flex: .65;">Action</mat-header-cell>
                            <mat-cell *matCellDef="let ltpMapping" style="flex: .65;">
                                <ng-container *ngIf="ltpMapping.isEditing">
                                    <input type="button" class="button" value="Save" (click)="updateCarrierRegedLPT(ltpMapping, $event.target)" />
                                    <input type="button" class="button" value="Cancel" (click)="cancelCarrierRegedLTP(ltpMapping)" />
                                </ng-container>
                                <ng-container *ngIf="!ltpMapping.isEditing">
                                    <input type="button" class="button" value="Edit" (click)="editLTP(ltpMapping)" /> <input type="button" class="button" value="Remove" (click)="deleteCarrierRegedLTP(ltpMapping, $event.target)" />
                                </ng-container>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *cdkHeaderRowDef="['type', 'producerType', 'action']"></mat-header-row>
                        <mat-row *cdkRowDef="let row; columns: ['type', 'producerType', 'action']; index as i" class="{{(i%2 == 0) ? 'even' : 'odd'}}-row"></mat-row>

                    </mat-table>
                </div>

                <div class="biggerhalf-width top">
                    <div>
                        <h5 class="inline">
                            LOAs Allowed for Appointment
                            <span *ngIf="allowedLoaEditor" style="margin-left: 10px;" title="Last Updated on {{allowedLoaEditTime | date:'mediumDate'}} by {{allowedLoaEditor}}"><svg class="info-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z" /><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg></span>
                        </h5>
                        <input type="button" class="button float-right" value="Add" style="margin: 20px 0 0 0;" (click)="addLoaAllowed()" />
                    </div>
                    <mat-table [dataSource]="filteredLOAs" *ngIf="activeCarrier.allowedLoas && activeCarrier.allowedLoas.length > 0">

                        <ng-container matColumnDef="loa">
                            <mat-header-cell *matHeaderCellDef>LOA</mat-header-cell>
                            <mat-cell *matCellDef="let loaMapping" class="proper-name">
                                <ng-container *ngIf="loaMapping.isEditing">
                                    <select name="loaMappingSelect" [(ngModel)]="loaMapping.regedLOA">
                                        <option *ngFor="let loa of stateRegedLOAs" [ngValue]="loa">{{loa.loa}} - {{loa.niprCode}}</option>
                                    </select>
                                </ng-container>
                                <ng-container *ngIf="!loaMapping.isEditing">
                                    {{loaMapping.regedLOA.loa}}
                                </ng-container>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="loaCode">
                            <mat-header-cell style="flex: .5;" *matHeaderCellDef>NIPR Code</mat-header-cell>
                            <mat-cell *matCellDef="let loaMapping" class="proper-name" style="flex: .5;">
                                {{loaMapping.regedLOA.niprCode}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="producerType">
                            <mat-header-cell *matHeaderCellDef style="flex: .75;">Producer Type</mat-header-cell>
                            <mat-cell *matCellDef="let loaMapping" style="flex: .75;">
                                <ng-container *ngIf="loaMapping.isEditing">
                                    <select [(ngModel)]="loaMapping.producerType">
                                        <option value="Agent">Agent</option>
                                        <option value="Agency">Agency</option>
                                        <option value="All">All</option>
                                    </select>
                                </ng-container>
                                <ng-container *ngIf="!loaMapping.isEditing">
                                    {{loaMapping.producerType}}
                                </ng-container>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <mat-header-cell *matHeaderCellDef style="flex: .65;">Action</mat-header-cell>
                            <mat-cell *matCellDef="let loaMapping" style="flex: .65;">
                                <ng-container *ngIf="loaMapping.isEditing">
                                    <input type="button" class="button" value="Save" (click)="updateCarrierRegedLOA(loaMapping, $event.target)" />
                                    <input type="button" class="button" value="Cancel" (click)="cancelCarrierRegedLOA(loaMapping)" />
                                </ng-container>
                                <ng-container *ngIf="!loaMapping.isEditing">
                                    <input type="button" class="button" value="Edit" (click)="editLOA(loaMapping)" /> <input type="button" class="button" value="Remove" (click)="deleteCarrierRegedLOA(loaMapping, $event.target)" />
                                </ng-container>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *cdkHeaderRowDef="['loa', 'loaCode', 'producerType', 'action']"></mat-header-row>
                        <mat-row *cdkRowDef="let row; columns: ['loa', 'loaCode', 'producerType', 'action']; index as i" class="{{(i%2 == 0) ? 'even' : 'odd'}}-row"></mat-row>

                    </mat-table>
                </div>

            </div>
        </div>

    </div>
</div>
