<ng-container class="alert alert-danger" *ngIf="errorMessage">
    {{ errorMessage }}
</ng-container>
<a class="button float-right" data-cy="buttonEntryToAddUser" (click)="showAddUser()">Add User</a>
<h3>Manage User Authorization</h3>
<p style="margin-bottom: 10px;" class="italics">New users must first be given access to IPA through a Software Request ticket in <a href="https://mspworx.service-now.com/tmhcc?id=tmhcc_tickets" target="_blank">Service Now</a></p>
<mat-table *ngIf="localCopyOfAllUsers" [dataSource]="localCopyOfAllUsers">

    <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef>e-mail</mat-header-cell>
        <mat-cell data-cy="cellEmail" *matCellDef="let user">{{user.userIdentifier}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="businessUnit">
        <mat-header-cell *matHeaderCellDef>Business Unit</mat-header-cell>
        <mat-cell *matCellDef="let user">{{user.businessUnit.name}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="comment">
        <mat-header-cell *matHeaderCellDef class="checkbox-col" style="width: 10%;" title="Users can add comments to Producers and Applications">Comment</mat-header-cell>
        <mat-cell data-cy="cellRoleComment" *matCellDef="let user" class="checkbox-col"><mat-checkbox [(ngModel)]="user.userRoles.comment" (change)="updateUserRoles(user, 'comment', $event.checked)"></mat-checkbox></mat-cell>
    </ng-container>

    <ng-container matColumnDef="reports">
        <mat-header-cell *matHeaderCellDef class="checkbox-col" style="width: 10%;" title="Users can run and view Reports">Reports</mat-header-cell>
        <mat-cell data-cy="cellRoleReport" *matCellDef="let user" class="checkbox-col"><mat-checkbox [(ngModel)]="user.userRoles.reports" (change)="updateUserRoles(user, 'reports', $event.checked)"></mat-checkbox></mat-cell>
    </ng-container>

    <ng-container matColumnDef="seed">
        <mat-header-cell *matHeaderCellDef class="checkbox-col" style="width: 10%;" title="Users can Seed new Producers to the database">Seed</mat-header-cell>
        <mat-cell data-cy="cellRoleSeed" *matCellDef="let user" class="checkbox-col"><mat-checkbox [(ngModel)]="user.userRoles.seed" (change)="updateUserRoles(user, 'seed', $event.checked)"></mat-checkbox></mat-cell>
    </ng-container>

    <ng-container matColumnDef="documents">
        <mat-header-cell *matHeaderCellDef class="checkbox-col" style="width: 10%;" title="Users can view Documents">Documents</mat-header-cell>
        <mat-cell data-cy="cellRoleDocuments" *matCellDef="let user" class="checkbox-col"><mat-checkbox [(ngModel)]="user.userRoles.documents" (change)="updateUserRoles(user, 'documents', $event.checked)"></mat-checkbox></mat-cell>
    </ng-container>

    <ng-container matColumnDef="onboard">
        <mat-header-cell *matHeaderCellDef class="checkbox-col" style="width: 10%;" title="Users can initiate Applications to Onboard Producers, and review and approve Applications">Onboard</mat-header-cell>
        <mat-cell data-cy="cellRoleOnboard" *matCellDef="let user" class="checkbox-col"><mat-checkbox [(ngModel)]="user.userRoles.onboard" (change)="updateUserRoles(user, 'onboard', $event.checked)"></mat-checkbox></mat-cell>
    </ng-container>

    <ng-container matColumnDef="app-manager">
        <mat-header-cell *matHeaderCellDef class="checkbox-col" style="width: 10%;" title="Users can provide a second review on Applications">Manager</mat-header-cell>
        <mat-cell data-cy="cellRoleManager" *matCellDef="let user" class="checkbox-col"><mat-checkbox [(ngModel)]="user.userRoles.applicationManager" (change)="updateUserRoles(user, 'applicationManager', $event.checked)"></mat-checkbox></mat-cell>
    </ng-container>

    <ng-container matColumnDef="owner">
        <mat-header-cell *matHeaderCellDef class="checkbox-col" style="width: 10%;" title="Users can manage users within their Business Unit">BU Owner</mat-header-cell>
        <mat-cell data-cy="cellRoleOwner" *matCellDef="let user" class="checkbox-col"><mat-checkbox [(ngModel)]="user.userRoles.owner" (change)="updateUserRoles(user, 'owner', $event.checked)"></mat-checkbox></mat-cell>
    </ng-container>

    <ng-container matColumnDef="system-admin">
        <mat-header-cell *matHeaderCellDef class="checkbox-col" style="width: 10%;" title="Users can manage site specific">System Admin</mat-header-cell>
        <mat-cell data-cy="cellRoleSystemAdmin" *matCellDef="let user" class="checkbox-col"><mat-checkbox [(ngModel)]="user.userRoles.systemAdmin" (change)="updateUserRoles(user, 'systemAdmin', $event.checked)"></mat-checkbox></mat-cell>
    </ng-container>

    <ng-container matColumnDef="remove">
        <mat-header-cell *matHeaderCellDef class="checkbox-col" style="width: 10%;" title="Remove the user from the Business Unit">Remove</mat-header-cell>
        <mat-cell data-cy="cellRemoveUser" *matCellDef="let user" class="checkbox-col"><span *ngIf="currentUserRole.userRoles.owner"><a class="button" (click)="removeUser(user.id)">Remove</a></span></mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="columns"></mat-header-row>
    <mat-row *cdkRowDef="let row; columns: columns; index as i" class="{{(i%2 == 0) ? 'even' : 'odd'}}-row"></mat-row>

</mat-table>

<div *ngIf="addingUser">
    <div class="pv-modal">
        <div class="pv-modal-body">
            <a class="button warning-btn float-right" (click)="closeSearch()">Cancel</a>
            <mat-form-field class="full-width">
                <mat-label>User's Email</mat-label>
                <input data-cy="inputUserEmail" #inputUserEmail name="input-email" matInput type="text" required />
            </mat-form-field>
            <a class="button" data-cy="buttonAddUser" (click)="searchUser(currentUserRole, inputUserEmail.value)">Add User</a>
        </div>
    </div>
    <div class="pv-modal-background"></div>
</div>
