<div style="display: inline-block; margin: 10px 0;">
    <mat-radio-group [(ngModel)]="showList">
        <mat-radio-button *ngIf="showBUList" name="bu-select-toggle" value="bu" (change)="updateLists(true)">Filter on Business Unit</mat-radio-button>
        <mat-radio-button name="carrier-select-toggle" value="carrier" (change)="updateLists(false)">Filter on Carrier</mat-radio-button>
    </mat-radio-group>

    <div style="margin: 12px 0 0 20px;">
        <pv-biz-unit-multiselect *ngIf="showBUList && showList === 'bu'" [(bizUnits)]="bizUnits" (bizUnitsChange)="bizUnitsChange.emit(bizUnits)"
                                 [fromShell]="true"
                                 [fullBuList]="fullBuList"></pv-biz-unit-multiselect>

        <pv-carrier-multiselect *ngIf="showList === 'carrier'" [(carriers)]="carriers" (carriersChange)="carriersChange.emit(carriers)"
                                [fromShell]="true"
                                [fullCarrierList]="fullCarrierList"></pv-carrier-multiselect>
    </div>
</div>
