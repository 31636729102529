import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SiteManagementShellComponent } from './site-management-shell.component';

@Injectable({
    providedIn: 'root'
})
export class SiteManagementChangesNotSavedGuard implements CanDeactivate<SiteManagementShellComponent> {
    canDeactivate(
        component: SiteManagementShellComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        if (component.isAnyUserChangeNotSaved) {
            const msg = `You have changes not saved, are you sure to leave?`;
            return confirm(msg);
        }
        return true;
    }

}
