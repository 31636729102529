
<ng-container *ngIf="bizUnit.isCorporate && roles.systemAdmin">
    <h3>Manage Dashboard Alerts</h3>
    <div class="panel">
        <div class="panel-contain">
            <h4>{{alert.id ? 'Update' : 'Add New'}} Alert</h4>
            <div>
                <mat-form-field>
                    <mat-label>Alert Type</mat-label>
                    <mat-select name="alert-type" [(ngModel)]="alert.category">
                        <mat-option *ngFor="let alertType of alertTypesList" [value]="alertType">{{alertType}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="date-picker" title="The Alert will not show on the Dashboard until this date">
                    <input name="start-date" matInput [(ngModel)]="alert.startDate" [matDatepicker]="startDatePicker" placeholder="Date to begin showing on the Dashboard">
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="date-picker" title="The Alert will automatically be removed from the Dashboard after this date">
                    <input name="end-date" matInput [(ngModel)]="alert.endDate" [matDatepicker]="endDatePicker" placeholder="Expiration Date">
                    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker></mat-datepicker>
                </mat-form-field>
                <div>
                    <p>{{alert.category}} Message</p>
                    <mat-form-field style="width: 75%; padding: 0;" appearance="outline">
                        <textarea name="message" matInput rows="2" [(ngModel)]="alert.message" required></textarea>
                    </mat-form-field>
                    <span class="float-right" style="margin-top: 5px;">
                        <button class="button" (click)="addOrUpdateAlert()" [ngClass]="{'disabled': messageFieldsEntered() !== ''}" [disabled]="messageFieldsEntered() !== ''" title="{{messageFieldsEntered()}}">{{alert.id?'Update':'Submit'}} Alert</button>
                        <button class="button" (click)="resetAlert()" *ngIf="alert.id">Cancel</button>
                    </span>
                </div>
            </div>

            <div *ngIf="existingAlerts">
                <h4>Upcoming Alerts</h4>
                <div *ngFor="let alrt of existingAlerts" style="margin: 10px 0;">
                    <button class="button" (click)="editAlert(alrt)">Edit</button>
                    <button class="button" (click)="deleteAlert(alrt)">Delete</button>
                    <div class="inline" style="width: 80%; vertical-align: middle;">
                        <span><span class="bold">{{alrt.heading()}}: {{alrt.startDate | date:'mediumDate'}} - {{alrt.endDate | date:'mediumDate'}}</span>: {{alrt.message}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
