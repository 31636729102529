<div class="">
    <mat-form-field class="date-picker">
        <input name="start-date" matInput [(ngModel)]="startDate" [matDatepicker]="reportsStartDatePicker" placeholder="On or After Date" (ngModelChange)="startDateChange.emit(startDate)">
        <mat-datepicker-toggle matSuffix [for]="reportsStartDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #reportsStartDatePicker></mat-datepicker>
    </mat-form-field>
</div>
<div class="">
    <mat-form-field class="date-picker">
        <input name="end-date" matInput [(ngModel)]="endDate" [matDatepicker]="reportsEndDatePicker" placeholder="On or Before Date" (ngModelChange)="endDateChange.emit(endDate)">
        <mat-datepicker-toggle matSuffix [for]="reportsEndDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #reportsEndDatePicker></mat-datepicker>
    </mat-form-field>
</div>
