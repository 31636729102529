<div style="margin: -10px 0 10px;" *ngIf="!loading && producerService.pdbAppointments && userDefaultCarrierFilter.length > 0">
    <p *ngIf="defaultPrefFilterActive">
        Data is being filtered to user default carriers: <a href="javascript:void(0);" (click)="toggleCarrierPreference()">Remove Default Carriers Filter</a>
    </p>
    <p *ngIf="!defaultPrefFilterActive">
        <a href="javascript:void(0);" (click)="toggleCarrierPreference()">Apply Default Carriers Filter</a>
    </p>
</div>

<ng-container *ngIf="loading">
    <div class="loading-spinner"></div>
</ng-container>

<ng-container *ngIf="!loading && !producerService.producerResultFromNipr && producerService.pdbAppointments && producerService.pdbAppointments.length > 0">
  <mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="companyName">
      <mat-header-cell *matHeaderCellDef>
        <span *ngIf="!showFilter">Company Name</span>
        <mat-form-field *ngIf="showFilter" class="filter" style="width: 120px; padding: 0;">
          <mat-label class="white">Company Name</mat-label>
          <input matInput [formControl]="companyNameFilter" />
        </mat-form-field>
      </mat-header-cell>
      <mat-cell *matCellDef="let appt">{{appt.companyName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="fein">
      <mat-header-cell *matHeaderCellDef>
        <span *ngIf="!showFilter">FEIN</span>
        <mat-form-field *ngIf="showFilter" class="filter" style="width: 80px; padding: 0;">
          <mat-label class="white">FEIN</mat-label>
          <input matInput [formControl]="feinFilter" />
        </mat-form-field>
      </mat-header-cell>
      <mat-cell *matCellDef="let appt">{{appt.fein}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="state">
      <mat-header-cell *matHeaderCellDef>
        <span *ngIf="!showFilter">State</span>
        <mat-form-field *ngIf="showFilter" class="filter" style="width: 60px; padding: 0;">
          <mat-label class="white">State</mat-label>
          <mat-select [formControl]="stateFilter">
            <mat-option *ngFor="let state of stateList" [value]="state">{{state}}</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-header-cell>
      <mat-cell *matCellDef="let appt">{{appt.state}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="loaCode">
      <mat-header-cell *matHeaderCellDef>
        <span *ngIf="!showFilter">Line of Authority</span>
        <mat-form-field *ngIf="showFilter" class="filter" style="width: 140px; padding: 0;">
          <mat-label class="white">Line of Authority</mat-label>
          <mat-select [formControl]="loaFilter">
            <mat-option *ngFor="let loa of lineOfAuthList" [value]="loa">{{loa}}</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-header-cell>
      <mat-cell *matCellDef="let appt" title="Appt LOA Code: {{appt.loaCode}}">{{appt.loaDescription}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>
        <span *ngIf="!showFilter">Status</span>
        <mat-form-field *ngIf="showFilter" class="filter" style="width: 80px; padding: 0;">
          <mat-label class="white">Status</mat-label>
          <mat-select [formControl]="statusFilter">
            <mat-option *ngFor="let status of staticStatusList" [value]="status">{{status}}</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-header-cell>
      <mat-cell *matCellDef="let appt">{{appt.displayStatus}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="termination">
      <mat-header-cell *matHeaderCellDef>
        <span *ngIf="!showFilter">Termination Reason</span>
        <mat-form-field *ngIf="showFilter" class="filter" style="width: 100px; padding: 0;">
          <mat-label class="white">Termination Reason</mat-label>
          <input matInput [formControl]="terminationFilter" />
        </mat-form-field>
      </mat-header-cell>
      <mat-cell *matCellDef="let appt">{{appt.terminationReason}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="effectiveDate">
      <mat-header-cell *matHeaderCellDef>Effective Date</mat-header-cell>
      <mat-cell *matCellDef="let appt">{{appt.effectiveDateTime | date:'mediumDate'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="expirationDate">
      <mat-header-cell *matHeaderCellDef>Expiration Date</mat-header-cell>
      <mat-cell *matCellDef="let appt">{{appt.expirationDate | date:'mediumDate'}}</mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="columns"></mat-header-row>
    <mat-row *cdkRowDef="let row; columns: columns; index as i" class="{{(i%2 == 0) ? 'even' : 'odd'}}-row" title="{{row.dataSource === 'Local' ? 'This is a LOCAL Appointment' : ''}}"></mat-row>

  </mat-table>

</ng-container>

<div [hidden]="!loading && (!producerService.pdbAppointments || producerService.pdbAppointments.length == 0)">
    <mat-paginator [pageSize]="50"
                   [pageSizeOptions]="[25, 50, 100]">></mat-paginator>
</div>

<div class="panel-no-results" *ngIf="!loading && !producerService.producerResultFromNipr && ((producerService.pdbAppointments && producerService.pdbAppointments.length == 0) || producerService.producer.npn == null)">
  <h2>No Appointment Data Available</h2>
</div>

<ng-container *ngIf="producerService.producerResultFromNipr">
  <div class="panel-no-results">
    <h2>Results are from NIPR, information not available. Add producer to see information.</h2>
  </div>
</ng-container>

