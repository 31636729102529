<div style="margin-bottom: 40px;">
    <span class="label">CE Courses</span>
    <ng-container *ngIf="courses">
        <div class="details-list" style="width: 100%;" *ngIf="courses.length > 0">
            <mat-table [dataSource]="courses" style="margin-bottom: 10px;">

                <ng-container matColumnDef="courseNumber">
                    <mat-header-cell *matHeaderCellDef>
                        <span>Course Number</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let course">
                        <ng-container *ngIf="course.isEditing">
                            <input type="text" [(ngModel)]="course.courseNumber" />
                        </ng-container>
                        <ng-container *ngIf="!course.isEditing">
                            {{course.courseNumber}}
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="courseName">
                    <mat-header-cell *matHeaderCellDef>
                        <span>Course Name</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let course">
                        <ng-container *ngIf="course.isEditing">
                            <input type="text" [(ngModel)]="course.courseName" />
                        </ng-container>
                        <ng-container *ngIf="!course.isEditing">
                            {{course.courseName}}
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="courseStatus">
                    <mat-header-cell *matHeaderCellDef>
                        <span>Status</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let course">
                        <ng-container *ngIf="course.isEditing">
                            <select name="courseStatusSelect" [(ngModel)]="course.status">
                                <option value="Completed">Completed</option>
                                <option value="Cancelled">Cancelled</option>
                                <option value="Started">Started</option>
                            </select>
                        </ng-container>
                        <ng-container *ngIf="!course.isEditing">
                            {{course.status}}
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="courseHours">
                    <mat-header-cell *matHeaderCellDef>
                        <span>Course Hours</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let course">
                        <ng-container *ngIf="course.isEditing">
                            <input type="text" [(ngModel)]="course.courseHours" />
                        </ng-container>
                        <ng-container *ngIf="!course.isEditing">
                            {{course.courseHours}}
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="courseCategory">
                    <mat-header-cell *matHeaderCellDef>
                        <span>Course Category</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let course">
                        <ng-container *ngIf="course.isEditing">
                            <select name="courseCourseCategorySelect" [(ngModel)]="course.courseCategory">
                                <option *ngFor="let key of courseCatKeys" value="{{key}}">{{courseCategories[key]}}</option>
                            </select>
                        </ng-container>
                        <ng-container *ngIf="!course.isEditing">
                            {{courseCategories[course.courseCategory]}}
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="courseCompletionDate">
                    <mat-header-cell *matHeaderCellDef>
                        <span>Completion Date</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let course">
                        <ng-container *ngIf="course.isEditing">
                            <mat-form-field class="date-picker" style="width: 90%;">
                                <input name="course-completion-date" matInput [(ngModel)]="course.completionDate" [matDatepicker]="completionDatePicker" />
                                <mat-datepicker-toggle matSuffix [for]="completionDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #completionDatePicker></mat-datepicker>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="!course.isEditing">
                            {{course.completionDate | date:'mediumDate'}}
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="courseProviderId">
                    <mat-header-cell *matHeaderCellDef>
                        <span>Provider ID</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let course">
                        <ng-container *ngIf="course.isEditing">
                            <input type="text" [(ngModel)]="course.providerId" />
                        </ng-container>
                        <ng-container *ngIf="!course.isEditing">
                            {{course.providerId}}
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="courseProviderName">
                    <mat-header-cell *matHeaderCellDef>
                        <span>Provider Name</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let course">
                        <ng-container *ngIf="course.isEditing">
                            <input type="text" [(ngModel)]="course.providerName" />
                        </ng-container>
                        <ng-container *ngIf="!course.isEditing">
                            {{course.providerName}}
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="courseAction">
                    <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                    <mat-cell *matCellDef="let course">
                        <ng-container *ngIf="course.isEditing">
                            <input type="button" class="button" value="Save" (click)="updateCourse(course, $event.target)" />
                            <input type="button" class="button" value="Cancel" style="margin-left: 10px;" (click)="cancelEditCourse(course)" />
                        </ng-container>
                        <ng-container *ngIf="!course.isEditing">
                            <input type="button" class="button" value="Edit" style="margin-right: 10px;" (click)="editCourse(course)" />
                            <input type="button" class="button" value="Delete" (click)="deleteCourse(course, $event.target)" />
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <mat-header-row *cdkHeaderRowDef="columns"></mat-header-row>
                <mat-row *cdkRowDef="let row; columns: columns; index as i" class="{{(i%2 == 0) ? 'even' : 'odd'}}-row"></mat-row>

            </mat-table>

        </div>
        <button class="button" (click)="addCourse()">Add Course</button>
    </ng-container>
</div>
