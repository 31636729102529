<mat-form-field style="margin: 15px 40px 0 0; width: 350px;">
    <mat-label>Appoint to Carrier</mat-label>
    <mat-select [(ngModel)]="selectedCarrier" (selectionChange)="carrierChanged()">
        <mat-option *ngFor="let carrier of bizUnitCarriers$ | async" [value]="carrier">{{carrier.name}}</mat-option>
    </mat-select>
</mat-form-field>

<mat-form-field class="date-picker" style="width:130px;">
    <mat-label>Effective Date</mat-label>
    <input name="appt-effective-date" matInput [(ngModel)]="effectiveDate" [matDatepicker]="appEffectiveDatePicker">
    <mat-datepicker-toggle matSuffix [for]="appEffectiveDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #appEffectiveDatePicker></mat-datepicker>
</mat-form-field>

<label style="margin-left: 40px;">
    <mat-checkbox name="inactive-toggle" [(ngModel)]="showInactiveLicenses"></mat-checkbox>
    Show Expired/Inactive
</label>

    <pv-new-appointment [licenses]="producerService.licenses"
                        [carrier]="updatedCarrier"
                        [carrierAppointments]="producerService.carrierAppointments"
                        [pobAppointments]="producerService.pobAppointments"
                        [producer]="producerService.producer"
                        [bizUnitId]="bizUnitId"
                        [showInactive]="showInactiveLicenses"
                        (appointmentsSelected)="handleApptSelection($event)"
                        (terminationSubmitted)="handleTermination($event)"
                        (localAppointmentsSelected)="handleLocalAppt($event)">
    </pv-new-appointment>
