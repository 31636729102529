
<ng-container *ngIf="loading">
    <div class="loading-spinner"></div>
</ng-container>

<ng-container *ngIf="licenses && !loading">
    <div class="float-right" style="margin-top: 30px;">
        <button class="button" style="margin-left: 20px; font-size: medium;" [ngClass]="{'disabled': appointDisabled()}" [disabled]="appointDisabled()" (click)="appointSelected()">{{buttonText}}</button>
    </div>

    <mat-table class="clear-float" *ngIf="licenses.length > 0" [dataSource]="dataSource">

        <ng-container matColumnDef="licenseNumber">
            <mat-header-cell *matHeaderCellDef style="">
                <span *ngIf="!showFilter">License Number</span>
                <mat-form-field *ngIf="showFilter" class="filter" style="width: 80px; padding: 0;">
                    <mat-label class="white">License Number</mat-label>
                    <input matInput [formControl]="licenseNumberFilter" />
                </mat-form-field>
            </mat-header-cell>
            <mat-cell *matCellDef="let license" style="">{{license.licenseNumber}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="state">
            <mat-header-cell *matHeaderCellDef style="">
                <span *ngIf="!showFilter">State</span>
                <mat-form-field *ngIf="showFilter" class="filter" style="width: 60px; padding: 0;">
                    <mat-label class="white">State</mat-label>
                    <mat-select [formControl]="stateFilter">
                        <mat-option *ngFor="let state of stateList" [value]="state">{{state}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-header-cell>
            <mat-cell *matCellDef="let license" style="">
                <div>
                    <p>{{license.stateCode}}</p>
                    <ng-container *ngIf="license.stateCode === 'FC'">
                        <p>
                            <select name="county" id="florida-county-select" class="required" [(ngModel)]="license.floridaCountyCode" (ngModelChange)="countyChanged()">
                                <option class="option-disabled" value="0" disabled selected>Select FL County...</option>
                                <option *ngFor="let county of floridaCountyList" [ngValue]="county.code">{{county.name}}</option>
                            </select>
                        </p>
                    </ng-container>
                    <ng-container *ngIf="license.stateCode === 'SC'">
                        <p>
                            <select name="appointment-type" class="required" [(ngModel)]="license.licenseTypeText" onchange="this.removeAttribute('class');">
                                <option class="option-disabled" value="0" disabled selected>Appointment Type</option>
                                <option [ngValue]="1">Local</option>
                                <option [ngValue]="4">General</option>
                            </select>
                        </p>
                    </ng-container>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="class">
            <mat-header-cell *matHeaderCellDef style="">
                <span *ngIf="!showFilter">Class</span>
                <mat-form-field *ngIf="showFilter" class="filter" style="width: 100px; padding: 0;">
                    <mat-label class="white">Class</mat-label>
                    <input matInput [formControl]="classFilter" />
                </mat-form-field>
            </mat-header-cell>
            <mat-cell *matCellDef="let license" style="" title="{{license.class}}">{{license.class}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="effectiveDate">
            <mat-header-cell *matHeaderCellDef style="">Effective Date</mat-header-cell>
            <mat-cell *matCellDef="let license" style="">{{license.effectiveDate | date:'mediumDate'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="expirationDate">
            <mat-header-cell *matHeaderCellDef style="">Expiration Date</mat-header-cell>
            <mat-cell *matCellDef="let license" style="">{{license.expirationDate | date:'mediumDate'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="loaCode">
            <mat-header-cell *matHeaderCellDef style="">
                <span *ngIf="!showFilter">Line of Authority</span>
                <mat-form-field *ngIf="showFilter" class="filter" style="width: 140px; padding: 0;">
                    <mat-label class="white">Line of Authority</mat-label>
                    <mat-select [formControl]="loaFilter">
                        <mat-option *ngFor="let loa of lineOfAuthList" [value]="loa">{{loa}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-header-cell>
            <mat-cell *matCellDef="let license" style=""><div><p *ngFor="let loa of license.loaArray" [ngClass]="{ 'italics': license.loaNotReq }" title="{{loa}}">{{loa}}</p></div></mat-cell>
        </ng-container>

        <ng-container matColumnDef="appoint">
            <mat-header-cell *matHeaderCellDef class="center inline" style="">Appoint</mat-header-cell>
            <mat-cell *matCellDef="let license" style="">
                <div class="center" style="width: 100%;">
                    <ng-container *ngIf="license.details.length > 0 && !license.loaNotReq">
                        <p *ngFor="let det of license.details">
                            <!-- First Appointed state, deal with termination / status -->
                            <ng-container *ngIf="det.appointed === true">
                                <ng-container *ngIf="!onboarding">
                                    <ng-container *ngIf="det.inProgress === true && det.filingType === 'Appointing' && det.isErrorStatus === false">
                                        <a href="javascript:void(0);" [routerLink]="['/AppointProducer/' + producer.id + '/InProgressAppointment']" title="{{det.apptStatus}}">{{det.displayStatus}}</a>
                                    </ng-container>
                                    <ng-container *ngIf="det.filingType === 'Appointing' && det.isErrorStatus === true">
                                        <input type="checkbox" [(ngModel)]="det.selected" title="{{det.description}}" [disabled]="license.isDisabled" /><a *ngIf="det.isErrorStatus" href="javascript:void(0);" title="Previous Appointment Failed! Click to see status" [routerLink]="['/AppointProducer/' + producer.id + '/InProgressAppointment']"><span class="alert-icon"></span></a>
                                    </ng-container>
                                </ng-container>
                                <!-- During onboarding, don't want to concern user with in progress appointment stuff, just indicate appointment is already there -->
                                <ng-container *ngIf="onboarding || (det.inProgress !== true || det.filingType !== 'Appointing')">
                                    <span class="italics" *ngIf="det.isLocal">Recorded</span>
                                    <span class="italics" *ngIf="!det.isLocal">Appointed</span>
                                </ng-container>
                            </ng-container>
                            <!-- Second, not Appointed, allow selection for appointment -->
                            <ng-container *ngIf="det.appointed === false">
                                <ng-container *ngIf="det.inProgress === true && det.isErrorStatus === false">
                                    <a *ngIf="det.apptStatus !== 'AttachedToApplication'" href="javascript:void(0);" [routerLink]="['/AppointProducer/' + producer.id + '/InProgressAppointment']" title="{{det.apptStatus}}">{{det.displayStatus}}</a>
                                    <span *ngIf="det.apptStatus === 'AttachedToApplication'" class="italics" title="{{det.apptStatus}}">{{det.displayStatus}}</span>
                                </ng-container>
                                <ng-container *ngIf="det.inProgress === false || det.isErrorStatus === true"><input type="checkbox" [(ngModel)]="det.selected" title="{{det.description}}" [disabled]="license.isDisabled" /><a *ngIf="det.isErrorStatus" href="javascript:void(0);" title="Previous Appointment Failed! Click to see status" [routerLink]="['/AppointProducer/' + producer.id + '/InProgressAppointment']"><span class="alert-icon"></span></a></ng-container>
                            </ng-container>
                        </p>
                    </ng-container>
                    <ng-container *ngIf="license.details.length === 0 || license.loaNotReq">
                        <p>
                            <!-- Need to consolidate this block with the one above into a new component... they're basically identical -->
                            <ng-container *ngIf="license.appointed === true">
                                <ng-container *ngIf="!onboarding">
                                    <ng-container *ngIf="license.inProgress === true && license.filingType === 'Appointing' && license.isErrorStatus === false">
                                        <a href="javascript:void(0);" [routerLink]="['/AppointProducer/' + producer.id + '/InProgressAppointment']" title="{{license.apptStatus}}">{{license.displayStatus}}</a>
                                    </ng-container>
                                    <ng-container *ngIf="license.filingType === 'Appointing' && license.isErrorStatus === true">
                                        <input type="checkbox" [(ngModel)]="license.selected" title="{{license.description}}" [disabled]="license.isDisabled" /><a *ngIf="license.isErrorStatus" href="javascript:void(0);" title="Previous Appointment Failed! Click to see status" [routerLink]="['/AppointProducer/' + producer.id + '/InProgressAppointment']"><span class="alert-icon"></span></a>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="onboarding || (license.inProgress !== true || license.filingType !== 'Appointing')">
                                    <span class="italics" *ngIf="license.isLocal">Recorded</span>
                                    <span class="italics" *ngIf="!license.isLocal">Appointed</span>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="license.appointed === false">
                                <ng-container *ngIf="license.inProgress === true && license.isErrorStatus === false">
                                    <a *ngIf="license.apptStatus !== 'AttachedToApplication'" href="javascript:void(0);" [routerLink]="['/AppointProducer/' + producer.id + '/InProgressAppointment']" title="{{license.apptStatus}}">{{license.displayStatus}}</a>
                                    <span *ngIf="license.apptStatus === 'AttachedToApplication'" class="italics" title="{{license.apptStatus}}">{{license.displayStatus}}</span>
                                </ng-container>
                                <ng-container *ngIf="license.inProgress === false || license.isErrorStatus === true"><input type="checkbox" title="{{license.loasSelectedDescription}}" [(ngModel)]="license.selected"  [disabled]="license.isDisabled" /><a *ngIf="license.isErrorStatus" href="javascript:void(0);" title="Previous Appointment Failed! Click to see status" [routerLink]="['/AppointProducer/' + producer.id + '/InProgressAppointment']"><span class="alert-icon"></span></a></ng-container>
                            </ng-container>
                        </p>
                        <p *ngIf="license.loaNotReq && (license.appointed === false || license.isErrorStatus === true)" class="italics">(LOA not required)</p>
                    </ng-container>

                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="terminate" *ngIf="!onboarding">
            <mat-header-cell *matHeaderCellDef class="center inline" style="">Terminate</mat-header-cell>
            <mat-cell *matCellDef="let license" style="">
                <div class="center" style="width: 100%;">
                    <ng-container *ngIf="license.details.length > 0 && !license.loaNotReq">
                        <p *ngFor="let det of license.details">
                            <!-- First Appointed state, deal with termination / status -->
                            <ng-container *ngIf="det.appointed === true">
                                <ng-container *ngIf="det.inProgress === true && det.isErrorStatus === false">
                                    <a href="javascript:void(0);" [routerLink]="['/AppointProducer/' + producer.id + '/InProgressAppointment']" title="{{det.apptStatus}}">{{det.displayStatus}}</a>
                                </ng-container>
                                <ng-container *ngIf="det.inProgress === false || det.isErrorStatus === true">
                                    <a class="alert-color" href="javascript:void(0);" (click)="terminateProducerBySource(license, det)">Terminate</a>
                                    <a *ngIf="det.isErrorStatus === true" href="javascript:void(0);" title="Termination Failed! Click to see status" [routerLink]="['/AppointProducer/' + producer.id + '/InProgressAppointment']"><span class="alert-icon"></span></a>
                                </ng-container>
                            </ng-container>
                        </p>
                    </ng-container>
                    <ng-container *ngIf="license.details.length === 0 || license.loaNotReq">
                        <p>
                            <!-- Need to consolidate this block with the one above into a new component... they're basically identical -->
                            <ng-container *ngIf="license.appointed === true">
                                <ng-container *ngIf="license.inProgress === true && license.isErrorStatus === false">
                                    <a href="javascript:void(0);" [routerLink]="['/AppointProducer/' + producer.id + '/InProgressAppointment']" title="{{license.apptStatus}}">{{license.displayStatus}}</a>
                                </ng-container>
                                <ng-container *ngIf="license.inProgress === false || license.isErrorStatus === true">
                                    <a class="alert-color" href="javascript:void(0);" (click)="terminateProducerBySource(license)">Terminate</a>
                                    <a *ngIf="license.isErrorStatus === true" href="javascript:void(0);" title="Termination Failed! Click to see status" [routerLink]="['/AppointProducer/' + producer.id + '/InProgressAppointment']"><span class="alert-icon"></span></a>
                                </ng-container>
                            </ng-container>
                        </p>
                    </ng-container>
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *cdkHeaderRowDef="columns"></mat-header-row>
        <mat-row *cdkRowDef="let row; columns: columns; index as i" class="{{(i%2 == 0) ? 'even' : 'odd'}}-row"></mat-row>

    </mat-table>

    <ng-container *ngIf="terminating">
        <pv-terminate-appt (terminateConfirmed)="terminateConfirmed($event)" (terminating)="terminating = false" [producer]="producer" [bizUnitId]="bizUnitId" [license]="terminatingLicense" [detail]="terminatingLicenseDetail" [carrierName]="carrier.name" [(order)]="terminateOrder"></pv-terminate-appt>
    </ng-container>

</ng-container>

<div [hidden]="!loading && (!licenses || licenses.length == 0)">
    <mat-paginator [pageSize]="25"
                   [pageSizeOptions]="[25, 50, 100]">></mat-paginator>
</div>
