import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { ModalService } from '../modal/modal.service';
import { GetHttpErrorMessage, CheckClientDtoFailStatus, GetDtoErrorMessage } from '../shared/utils/error-handling';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RestService {
    constructor(private http: HttpClient, private config: AppConfigService, public modal: ModalService) { }

    get headers() {
        if (this.config.activeBizUnitId) {
            return { headers: { "BizUnitId": this.config.activeBizUnitId.toString() } };;
        }
    }

    //Call a GET API resource and handle errors yourself
    public getWrapped<T>(relativeUrl: string): Observable<T> {
        return this.http.get<T>(this.config.urlToMasterDbApi + relativeUrl, this.headers);
    }

    //Call a GET API resource with error handling
    public get<T>(relativeUrl: string, showError = true): Observable<T> {
        return this.getWrapped<T>(relativeUrl).pipe(
            map(res => {
                this.httpResultHandler<T>(res, showError)
                return res;
            }),
            catchError(err => {
                if (showError === true) {
                    this.modal.message(relativeUrl + " - " + GetHttpErrorMessage(err));
                }
                throw err;
            }));

    }

    //Call a POST API resource and handle errors yourself
    public postWrapped<T>(relativeUrl: string, data: any): Observable<T> {
        return this.http.post<T>(this.config.urlToMasterDbApi + relativeUrl, data, this.headers);
    }

    //Call a POST API resource with error handling
    public post<T>(relativeUrl: string, data: any, showError = true): Observable<T> {
        return this.postWrapped<T>(relativeUrl, data).pipe(
            map(res => {
                this.httpResultHandler<T>(res, showError)
                return res;
            }),
            catchError(err => {
                this.modal.message(GetHttpErrorMessage(err));
                throw err;
            }));
    }

    //Call a GET API resource and handle errors yourself
    public deleteWrapped<T>(relativeUrl: string, data: any): Observable<T> {
        if (data) {
            const options = {
                headers: this.headers.headers,
                body: data
            };
            return this.http.delete<T>(this.config.urlToMasterDbApi + relativeUrl, options);
        }
        else {
            return this.http.delete<T>(this.config.urlToMasterDbApi + relativeUrl, this.headers);
        }
    }

    //Call a GET API resource with error handling
    public delete<T>(relativeUrl: string, data: any = null, showError = true): Observable<T> {
        return this.deleteWrapped<T>(relativeUrl, data).pipe(
            map(res => {
                this.httpResultHandler<T>(res, showError)
                return res;
            }),
            catchError(err => {
                this.modal.message(GetHttpErrorMessage(err));
                throw err;
            }));

    }

    private httpResultHandler<T>(res: T, showError: boolean) {
        if (CheckClientDtoFailStatus(res)) {
            if (showError === true) {
                this.modal.message(GetDtoErrorMessage(res));
            }
        }
    }
}
